.rounded-btn {
  @apply rounded-full px-4 py-2 bg-secondary text-white font-light duration-200 transition-colors;
}

.rounded-btn:hover {
  background: #9cbc9c;
}

.small-btn {
  @apply outline-none text-xs tracking-wider font-medium border border-v2-accent px-4 py-2 rounded-full uppercase bg-white text-v2-accent transition-colors;
}

.small-btn:hover {
  @apply bg-v2-accent bg-opacity-10;
}

.small-btn:focus {
  @apply outline-none;
}

.small-btn:active {
  @apply outline-none;
}

.rounded-btn:focus {
  @apply outline-none;
}

.rounded-btn:disabled {
  background: #b7cab7;
  @apply cursor-default;
}

.default-btn {
  @apply rounded-md px-4 py-2 bg-white text-black font-light duration-200 transition-colors;
}

.input-label {
  @apply text-white uppercase text-xs tracking-widest font-semibold mb-2;
}

.input-textarea {
  @apply w-full p-2 rounded-md shadow-md focus:outline-none text-black;
}

.login-social-button {
  @apply border rounded-full border-v2-login-border p-2 ml-5 flex justify-center items-center bg-v2-background transition-all duration-300 hover:shadow-md hover:border-v2-accent cursor-pointer active:bg-v2-accent active:bg-opacity-10 active:shadow-none;
  width: 42px;
  height: 42px;
}

.text-input {
  @apply ring-1 ring-gray-300 font-medium text-sm py-2 px-2 rounded-md outline-none focus:ring-v2-secondary focus:ring-opacity-50 transition-all duration-300;
}

.wrapper {
  @apply py-28 relative;
}

.-mx-18 {
  margin-left: -4.5rem;
  margin-right: -4.5rem;
}

.h-profile-modal {
  height: 650px;
  min-height: 650px;
}
