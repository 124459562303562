html,
body {
  padding: 0;
  margin: 0;
}

body {
  overscroll-behavior-y: none;
}

.my-masonry-grid {
  display: flex;
  margin-left: -10px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 10px; /* gutter size */
  background-clip: padding-box;
}

/* width */
::-webkit-scrollbar {
  height: 8px;
  width: 8px;
  overflow: hidden;
}

/* Track */
.dark-scrollbar::-webkit-scrollbar-track {
  background: #454545;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

::-webkit-scrollbar-track {
  background: #fafafa;
}
